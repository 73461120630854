let _initialized = false
export function init(addIcon) {
  if (_initialized)
    return
  const collections = JSON.parse("[{\"prefix\":\"custom\",\"icons\":{\"moon-plus\":{\"width\":24,\"height\":24,\"body\":\"<g fill=\\\"none\\\" stroke=\\\"currentColor\\\" stroke-width=\\\"2\\\" stroke-linecap=\\\"round\\\" stroke-linejoin=\\\"round\\\"><path d=\\\"M16 5H22M19 2V8M11 3C10 4.19 9.13 5.81 9.13 7.5C9.13 9.19 10 10.81 11 12C12 13.19 13.81 13.86 15.5 13.86C17.19 13.86 18.81 13.19 20 12C20 13.78 19.47 15.52 18.48 17C17.49 18.48 16.09 19.63 14.44 20.31C12.8 21 10.99 21.17 9.24 20.83C7.5 20.48 5.89 19.62 4.63 18.36C3.38 17.11 2.52 15.5 2.17 13.76C1.83 12.01 2 10.2 2.68 8.56C3.36 6.91 4.52 5.51 6 4.52C7.48 3.53 9.22 3 11 3Z\\\" /></g>\"},\"sun-plus\":{\"width\":24,\"height\":24,\"body\":\"<g fill=\\\"none\\\" stroke=\\\"currentColor\\\" stroke-width=\\\"2\\\" stroke-linecap=\\\"round\\\" stroke-linejoin=\\\"round\\\"><path d=\\\"M11.95 20V22M4.91 4.85L6.32 6.26M17.58 17.64L19 19.06M2 11.95H4M6.32 17.64L4.91 19.06M15.93 11.95C15.93 14.17 14.15 15.97 11.95 15.97C9.75 15.97 7.97 14.17 7.97 11.95C7.97 9.73 9.75 7.93 11.95 7.93M14.5 5H21.5M18 1.5V8.5\\\" /></g>\"}}}]")
  for (const collection of collections) {
    for (const [name, data] of Object.entries(collection.icons)) {
      addIcon(collection.prefix ? (collection.prefix + ':' + name) : name, data)
    }
  }
  _initialized = true
}