<template>
  <VueDatePicker v-bind="forwarded" :ui="ui">
    <template #trigger>
      <slot name="trigger"></slot>
    </template>

    <template #input-icon>
      <Icon style="padding: 8px" icon="lucide:calendar" />
    </template>
  </VueDatePicker>
</template>

<script setup lang="ts">
import VueDatePicker, { type VueDatePickerProps, type UIOptions } from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useForwardPropsEmits } from "radix-vue";

export type Props = VueDatePickerProps & {
  hasBorder?: boolean;
};
export type Emits = {
  "update:modelValue": [value: Date | Date[] | null];
  textSubmit: [];
  open: [];
  closed: [];
  cleared: [];
  focus: [];
  blur: [];
  internalModelChange: [internalValue: Date | Date[], modelValue: any];
  recalculatePosition: [];
  flowStep: [step: number];
  updateMonthYear: [{ instance: number; month: number; year: number }];
  invalidSelect: [value: Date | Date[]];
  invalidFixedRange: [date: Date];
  tooltipOpen: [marker: any];
  tooltipClose: [marker: any];
  amPmChange: [value: "AM" | "PM"];
  rangeStart: [date: Date];
  rangeEnd: [date: Date];
  updateModelTimezoneValue: [value: Date | Date[]];
  dateUpdate: [date: Date];
  invalidDate: [date: Date];
  overlayToggle: [{ open: boolean; overlay: string }];
  textInput: [event: Event, parsedDate: Date | null];
};

const props = withDefaults(defineProps<Props>(), {
  autoApply: true,
  locale: "fr",
  timezone: "Europe/Paris",
  enableTimePicker: false,
  hasBorder: true,
});

const emit = defineEmits<Emits>();

const forwarded = useForwardPropsEmits(props, emit);

const ui: Partial<UIOptions & { border: string }> = {
  input: "custom-input-class",
};

const borderStyle = computed(() => {
  return props.hasBorder ? "1px solid var(--border)" : "none";
});
</script>

<style lang="scss">
:root {
  --dp-font-family: var(--font-family);
  --dp-time-font-size: 14px;
  --dp-cell-padding: 5px;
  --dp-border-radius: 10px;
}

.custom-input-class {
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--border);
  box-sizing: border-box;
  flex-grow: 1;
  color: var(--gray-1);
  background: var(--white);
  transition: outline-offset 0.1s ease-in-out;

  &:hover {
    border-color: var(--border-hover);
  }

  &:focus {
    outline: 2px solid var(--border-focus);
    outline-offset: 2px;
  }

  &.isError {
    border-color: var(--danger);

    &:focus {
      border-color: var(--danger);
      outline: 2px solid var(--danger-light);
      z-index: 1;
    }
  }

  &:disabled {
    background: var(--surface-disabled);
    cursor: not-allowed;

    &:hover {
      border: 1px solid var(--gray-5);
    }
  }

  &::placeholder {
    color: var(--gray-4);
  }
}

.dp__theme_light {
  --dp-primary-color: var(--primary);
  --border: v-bind(borderStyle);

  .dp__menu {
    border: v-bind(borderStyle);

    .dp__menu_inner {
      width: auto;
    }
  }

  .dp__calendar_item {
    .dp__cell_inner {
      border-radius: 9px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>
