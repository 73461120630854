<template>
  <nav class="nav">
    <div style="display: flex; align-items: center; gap: 8px; position: absolute; top: 12px; left: 16px">
      <NuxtLink to="/">
        <Button variant="ghost" size="icon">
          <ButtonIcon icon="lucide:arrow-left" />
        </Button>
      </NuxtLink>

      <div style="font-size: 18px; font-weight: 500; color: var(--gray-2)">Paramètres</div>
    </div>

    <div class="nav__content">
      <NavButtonGroup>
        <NavSection>Utilisateur</NavSection>

        <NuxtLink v-slot="{ isActive }" to="/settings/account">
          <NavButton :is-active="isActive">
            <TeamMemberAvatar v-if="teamMember" :team-member="teamMember" />
            <TextEllipsis> Mon compte </TextEllipsis>
          </NavButton>
        </NuxtLink>
      </NavButtonGroup>

      <NavSeparator />

      <NavButtonGroup>
        <NavSection>Général</NavSection>

        <NuxtLink v-slot="{ isActive }" to="/settings/workspace">
          <NavButton icon="lucide:building-2" :is-active="isActive">
            <TextEllipsis> Workspace </TextEllipsis>
          </NavButton>
        </NuxtLink>

        <NuxtLink v-slot="{ isActive }" to="/settings/members">
          <NavButton icon="lucide:users-2" :is-active="isActive">
            <TextEllipsis> Membres </TextEllipsis>
          </NavButton>
        </NuxtLink>
      </NavButtonGroup>
    </div>
  </nav>
</template>

<script setup lang="ts">
const { teamMember } = useUserMe();
</script>

<style scoped lang="scss">
.nav {
  display: flex;
  flex-direction: column;
  width: 204px;
  background: var(--white);
  box-shadow: var(--shadow-0);
  flex-shrink: 0;
  transition: var(--transition-2);
  overflow: hidden;
  padding-left: 256px;
  position: relative;

  &__content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-block: 16px;
    padding-inline: 12px;
    flex: 1;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
