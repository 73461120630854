import type { Database } from "@asap/shared/src/types/supabase.gen";
import type { PpeRequestStatus } from "@asap/shared/src/utils/supabase.enum";
import { endOfMonth, startOfMonth } from "date-fns";
import type { StatusBadgeVariant } from "~/components/ui/status-badge/types";
import { ppeRequestColumns } from "~/utils/supabase.columns";

interface PpeRequestFilters {
  status: PpeRequestStatus | "all";
  selectedDate?: Date;
  talentId?: string;
}

type PpeRequestState = {
  selectedFilters: PpeRequestFilters;
  showCreateModal: boolean;
};

export type PpeRequestPossibleAction = Exclude<PpeRequestStatus, "todo">;

const ppeRequestState = reactive<PpeRequestState>({
  selectedFilters: {
    status: "all",
  },
  showCreateModal: false,
});

export const usePpeRequest = () => {
  const supabase = useSupabase();

  const selectSingle = async function (id: string) {
    const { data, error } = await supabase.from("ppe_request").select(ppeRequestColumns).eq("id", id).single();
    if (error) throw new Error(error.message);
    return { data };
  };

  const selectMultipleForTable = async function (filters: PpeRequestFilters) {
    const { status, selectedDate, talentId } = filters;
    const { workspaceId, isCSM } = useUserMe();
    if (!workspaceId.value) throw new Error("Workspace ID is not defined");

    const query = supabase
      .from("ppe_request")
      .select(ppeRequestColumns, { count: "exact" })
      .order("status", { ascending: true })
      .order("created_at", { ascending: false });
    // If the user is not a CSM, we filter the requests by the workspace
    if (!isCSM.value) query.eq("workspace_id", workspaceId.value);

    if (status !== "all") query.eq("status", status);

    if (selectedDate) {
      const firstDayOfMonth = startOfMonth(selectedDate).toISOString();
      const lastDayOfMonth = endOfMonth(selectedDate).toISOString();
      query.gte("created_at", firstDayOfMonth).lte("created_at", lastDayOfMonth);
    }

    if (talentId) {
      query.eq("talent_id", talentId);
    }

    const { data, count, error } = await query;
    if (error) throw new Error(error.message);
    return { data, count };
  };

  const update = async function (id: string, values: Database["public"]["Tables"]["ppe_request"]["Update"]) {
    const { error } = await supabase
      .from("ppe_request")
      .update({
        ...values,
      })
      .eq("id", id)
      .select();
    if (error) throw new Error(error.message);
  };

  const insert = async (
    values: Database["public"]["Tables"]["ppe_request"]["Insert"] & { ppes: { item_id: string; attribute: string }[] }
  ) => {
    const { ppes, ...request } = values;

    const { data } = await insertRequest(request);

    const { error: insertPpeError } = await insertPpes(
      ppes.map((ppe) => ({
        request_id: data.id,
        item_id: ppe.item_id,
        attribute: ppe.attribute,
      }))
    );

    if (insertPpeError) {
      await supabase.from("ppe_request").delete().eq("id", data.id);
      throw new Error("Failed to insert ppes linked to request");
    }

    return { data };
  };

  const insertRequest = async (values: Database["public"]["Tables"]["ppe_request"]["Insert"]) => {
    const { data, error } = await supabase
      .from("ppe_request")
      .insert({ ...values })
      .select("id")
      .single();
    if (error) throw new Error(error.message);

    return { data };
  };

  const insertPpes = async (values: Database["public"]["Tables"]["ppe_request_item"]["Insert"][]) => {
    const { data, error } = await supabase.from("ppe_request_item").insert(values);
    return { data, error };
  };

  const mapButtonVariantToStatus = (status: PpeRequestStatus): StatusBadgeVariant => {
    switch (status) {
      case "pending":
        return "warning";
      case "todo":
      case "to_validate":
        return "danger";
      case "approved":
        return "success";
      case "refused":
        return "neutral";
      default:
        return "neutral";
    }
  };

  return {
    ppeRequestState,
    selectMultipleForTable,
    selectSingle,
    insert,
    update,
    mapButtonVariantToStatus,
  };
};
