<template>
  <FilterButton>
    <Button v-if="monthDate" variant="ghost" size="icon-small" class="change-month-button" @click="previousMonth">
      <Icon :icon="mapIcon.arrow_left" />
    </Button>
    <CalendarMonthPicker v-model:month-date="monthDate" :teleport="true">
      <template #trigger>
        <div style="display: flex; align-items: center; gap: 8px">
          <span v-if="!monthDate">Mois</span>
          <template v-if="monthDate">
            <Badge size="sm">
              {{ capitalize(format(monthDate, "MMMM yyyy", { locale: fr })) }}
            </Badge>
          </template>
        </div>
      </template>
    </CalendarMonthPicker>
    <Button v-if="monthDate" variant="ghost" size="icon-small" class="change-month-button" @click="nextMonth">
      <Icon :icon="mapIcon.arrow_right" />
    </Button>
    <FilterClear v-if="monthDate && isRemovable" @click.stop="monthDate = undefined" />
  </FilterButton>
</template>

<script setup lang="ts">
import { fr } from "date-fns/locale";
import { format, subMonths, addMonths } from "date-fns";
import { capitalize } from "lodash-es";
import { mapIcon } from "~/utils/icon";

withDefaults(
  defineProps<{
    isRemovable?: boolean;
  }>(),
  {
    isRemovable: true,
  }
);

const monthDate = defineModel<Date | undefined>("monthDate");

const previousMonth = () => {
  monthDate.value = monthDate.value ? subMonths(monthDate.value, 1) : undefined;
};

const nextMonth = () => {
  monthDate.value = monthDate.value ? addMonths(monthDate.value, 1) : undefined;
};
</script>

<style scoped>
.change-month-button {
  margin: 0 -8px;
  padding: 0px;
}
</style>
