import * as Sentry from "@sentry/vue";

/**
 * We use this logger for Frontend: we patch it with Sentry to capture errors.
 */

interface Logger {
  debug: (message?: any, ...optionalParams: any[]) => void;
  log: (message?: any, ...optionalParams: any[]) => void;
  info: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
}

class ConsoleLogger implements Logger {
  debug = console.debug;
  log = console.log;
  info = console.info;
  warn = console.warn;
  error = (error: unknown) => {
    Sentry.captureException(error);
    console.error(error);
  };
}

export const logger: Logger = new ConsoleLogger();
