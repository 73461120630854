<template>
  <Avatar :size="size">
    <AvatarImage v-if="authUser.avatar_file?.filestack_url" :src="downloadUrl(authUser.avatar_file.filestack_url)" />
    <AvatarFallback v-if="authUser.first_name" :color-key="authUser.id">
      {{ fallback(getFullName(authUser)) }}
    </AvatarFallback>
    <AvatarFallback v-else-if="authUser.email" :color-key="authUser.id">
      {{ fallback(authUser.email) }}
    </AvatarFallback>
  </Avatar>
</template>

<script setup lang="ts">
import type { AuthUser } from "~/utils/supabase.types";
import type { AvatarSize } from "~/components/ui/avatar/types";
import { useFilestackUtils } from "~/composables/useFilestack";
import { getFullName } from "@asap/shared/src/utils/people";

defineProps<{ authUser: AuthUser; size?: AvatarSize }>();

const { downloadUrl } = useFilestackUtils();

const fallback = (text: string) => text[0];
</script>
