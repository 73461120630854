<template>
  <div ref="navButtonRef" class="wrapper">
    <button class="button" :class="{ isActive, isNew }">
      <div class="buttonWrapper">
        <Icon v-if="icon" :icon="icon" class="button__icon" :class="{ isActive }" />
        <slot>Nav button</slot>
        <NotificationBadge :count="notificationCount" style="margin-left: auto" variant="red" />
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  image?: string;
  icon?: string;
  isNew?: boolean;
  isActive?: boolean;
  notificationCount?: number;
}>();

const navButtonRef = ref();
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  gap: 10px;

  &__vertical-line {
    width: 1px;
    margin-left: 19px;
    margin-top: -2px;
    margin-bottom: -2px;
    background: var(--gray-5);
    flex-shrink: 0;
  }
}

.button {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-2);
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  flex-shrink: 0;
  font-size: var(--size-14);
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;

  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  &:hover {
    background: var(--gray-6);
  }

  &.isActive {
    color: var(--orange);
    background: var(--orange-lighter);
  }

  &.isNew {
    cursor:
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👀</text></svg>")
        16 0,
      auto;
  }

  &__icon {
    height: 18px;
    width: 18px;
    color: var(--gray-3);
    transition: var(--transition-1);

    &.isActive {
      color: var(--orange);
    }
  }
}
</style>
