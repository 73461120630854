import type {
  ApiEndpoint,
  ApiEndpointMethod,
  ApiEndpointOutput,
  ApiEndpointResponse,
  ApiInvokeOptions,
} from "@asap/shared/src/clients/api";
import { ApiClient } from "@asap/shared/src/clients/api";

export const useApi = () => {
  const config = useRuntimeConfig();

  const loading = ref(false);

  const invoke = async <
    Path extends ApiEndpoint["path"],
    Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
  >(
    options: ApiInvokeOptions<Path, Method>
  ): Promise<ApiEndpointOutput<Path, Method, ApiEndpointResponse<Path, Method>>> => {
    const apiUrl = config.public.API_URL;
    const accessToken = useCookie("sb-access-token").value;
    if (!accessToken) throw new Error("Missing access token");

    loading.value = true;

    const apiClient = new ApiClient({
      apiUrl,
      accessToken,
      logger: console,
    });

    const response = await apiClient.invoke(options);

    loading.value = false;

    return response;
  };

  return { loading, invoke };
};
