import type {
  AdvancePaymentRequestStatus,
  AppRole,
  CommercialConditionPricingModel,
  CommercialConditionStatus,
  CommercialConditionType,
  CompanyInsuranceRequestStatus,
  ConstructionSiteSize,
  ConstructionType,
  ContractReason,
  ContractStatus,
  ContractType,
  DayOfWeek,
  DefinitiveEndType,
  DocumentType,
  EmployeeStatus,
  ExpenseClaimRequestStatus,
  ExpenseClaimRequestType,
  ExperienceLevel,
  Gender,
  IndependentPaymentRequestStatus,
  JobRisk,
  MealType,
  MedicalCenterStatus,
  MedicalCheckupRequestStatus,
  MedicalCheckupType,
  MissionStatus,
  Month,
  PayTrackingDocumentType,
  PayTrackingStatus,
  Ppe,
  PpeRequestStatus,
  QualificationCenterStatus,
  QualificationTrainingRequestStatus,
  RecruitmentOfficeInvoiceRequestStatus,
  ReferenceCheckStatus,
  RequestStatus,
  Sirh,
  SoftwareMasteryLevel,
  TeamSize,
  TimesheetWeekStatus,
  TrainingType,
  WorkAccidentRequestStatus,
} from "@asap/shared/src/utils/supabase.enum";

export const teamSizes = [
  "less_than_10_people",
  "from_10_to_20_people",
  "from_20_to_50_people",
  "more_than_50_people",
] as const satisfies readonly TeamSize[];

export const lowerTeamSizes = ["less_than_10_people", "from_10_to_20_people"] as const satisfies readonly TeamSize[];

export const higherTeamSizes = ["from_20_to_50_people", "more_than_50_people"] as const satisfies readonly TeamSize[];

export const genders = ["male", "female", "neutral"] as const satisfies readonly Gender[];

export const experienceLevels = [
  "from_0_to_1_year",
  "from_1_to_3_years",
  "from_3_to_6_years",
  "from_6_to_10_years",
  "more_than_10_years",
] as const satisfies readonly ExperienceLevel[];

export const higherExperienceLevels = [
  "from_6_to_10_years",
  "more_than_10_years",
] as const satisfies readonly ExperienceLevel[];

export const lowerExperienceLevels = [
  "from_0_to_1_year",
  "from_1_to_3_years",
  "from_3_to_6_years",
] as const satisfies readonly ExperienceLevel[];

export const constructionSiteSizes = [
  "less_than_100K",
  "from_100K_to_500K",
  "from_500K_to_1M",
  "from_1M_to_10M",
  "more_than_10M",
] as const satisfies readonly ConstructionSiteSize[];

export const lowerConstructionSiteSizes = [
  "less_than_100K",
  "from_100K_to_500K",
  "from_500K_to_1M",
] as const satisfies readonly ConstructionSiteSize[];

export const higherConstructionSiteSizes = [
  "from_1M_to_10M",
  "more_than_10M",
] as const satisfies readonly ConstructionSiteSize[];

export const employeeStatusValues = ["executive", "worker", "technician"] as const satisfies readonly EmployeeStatus[];

export const contractTypes = ["interim", "permanent", "temporary"] as const satisfies readonly ContractType[];

export const contractReasons = [
  "employee_replacement",
  "increased_company_actvity",
  "talent_professional_training",
] as const satisfies readonly ContractReason[];

export const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const satisfies readonly DayOfWeek[];

export const jobRisks = [
  "burial_or_sinking",
  "dust_treatments_milling",
  "fall_from_height",
  "handling_heavy_objects",
  "manual_handling",
  "demolition",
  "electrical_risks",
  "use_of_chemical_products",
  "use_of_dangerous_tools",
  "working_under_traffic",
] as const satisfies readonly JobRisk[];

export const ppes = [
  "hearing_protection_helmet",
  "protective_glasses",
  "protective_gloves",
  "safety_helmet",
  "safety_vest",
  "safety_pants",
  "safety_shoes",
  "comfort_parka",
  "comfort_polar_vest",
  "comfort_earplugs",
] as const satisfies readonly Ppe[];

export const constructionTypes = [
  "new_build",
  "maintenance",
  "renovation",
  "rehabilitation",
] as const satisfies readonly ConstructionType[];

export const sirhNames = ["baps", "peopulse", "pixid", "by_hand"] as const satisfies readonly Sirh[];

export const referenceCheckStatus = [
  "old",
  "todo",
  "done",
  "canceled",
] as const satisfies readonly ReferenceCheckStatus[];

export const requestStatus = ["pending", "approved", "canceled", "refused"] as const satisfies readonly RequestStatus[];

export const medicalCheckupRequestStatus = [
  "todo",
  "pending",
  "approved",
  "done",
  "canceled",
] as const satisfies readonly MedicalCheckupRequestStatus[];

export const qualificationTrainingRequestStatus = [
  "todo",
  "pending",
  "approved",
  "done",
  "canceled",
] as const satisfies readonly QualificationTrainingRequestStatus[];

export const medicalCheckupTypes = ["VIPI", "SIR", "SIA"] as const satisfies readonly MedicalCheckupType[];

export const medicalCenterStatus = ["approved", "pending", "refused"] as const satisfies readonly MedicalCenterStatus[];
export const ppeRequestStatus = [
  "to_validate",
  "todo",
  "pending",
  "approved",
  "refused",
] as const satisfies readonly PpeRequestStatus[];
export const qualificationCenterStatus = [
  "approved",
  "pending",
  "refused",
] as const satisfies readonly QualificationCenterStatus[];

export const contractStatus = [
  "draft",
  "to_process",
  "to_validate",
  "to_send",
  "waiting_for_signature",
  "signed",
  "cancelled",
  "refused",
] as const satisfies readonly ContractStatus[];

export const advancePaymentRequestStatus = [
  "pending",
  "pending_transfer_confirmation",
  "paid",
  "approved",
  "canceled",
  "refused",
  "error",
] as const satisfies readonly AdvancePaymentRequestStatus[];

export const independentPaymentRequestStatus = [
  "pending",
  "approved",
  "refused",
  "paid",
  "canceled",
] as const satisfies readonly IndependentPaymentRequestStatus[];

export const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
] as const satisfies readonly Month[];

export const softwareMasteryLevels = [
  "beginner",
  "junior",
  "confirmed",
  "senior",
  "expert",
] as const satisfies readonly SoftwareMasteryLevel[];

export const definitiveEndTypes = [
  "sick_leave",
  "found_permanent_job_elsewhere",
  "technical_bug",
  "worker_leave",
  "permanent_hire",
  "permanent_hire_by_current_client",
  "end_of_mission_by_worker",
  "end_of_mission_by_client",
  "end_of_mission",
  "competition_flip_flop",
  "not_qualified",
  "talent_no_show",
  "client_no_show",
  "will_no_longer_work_with_us",
  "contract_renewal",
  "negative_flexibility",
  "positive_flexibility",
  "contract_new",
  "contract_new_with_different_client",
  "end_of_trial_period",
] as const satisfies readonly DefinitiveEndType[];

export const trainingTypes = ["hybrid", "in-person", "remote"] as const satisfies readonly TrainingType[];

export const companyInsuranceRequestStatus = [
  "new",
  "sent",
  "approved",
  "refused",
  "approved_temporarily",
  "approved_but_only_contract_minimum",
  "approved_but_maximum_reached",
] as const satisfies readonly CompanyInsuranceRequestStatus[];

export const documentTypes = [
  "admin__identity__id_card",
  "admin__identity__passeport",
  "admin__identity__residence_permit",
  "admin__identity__residence_permit_receipt",
  "admin__banking__rib",
  "admin__health_insurance__vital_card",
  "admin__health_insurance__social_security_certificate",
  "admin__health_insurance__european_health_insurance_card",
  "admin__residence__proof_of_address",
  "admin__residence__accomodation_certificate",
  "admin__residence__proof_of_identity_of_the_host",
  "admin__residence__proof_of_address_of_the_host",
  "professional__driving_licence",
  "professional__qualification_certificate",
  "other__legal__criminal_record",
  "other__misc",
  "professional__cover_letter",
  "professional__reference_letter",
  "professional__professional_card_btp",
  "admin__health__medical_certificate",
  "contract__recruitment_office__invoice_draft",
  "contract__recruitment_office__invoice_real",
  "contract__recruitment_office__payment",
  "admin__health__medical_convocation",
  "admin__qualification_training_request",
  "other__company_insurance__response",
  "admin__contract__document",
] as const satisfies readonly DocumentType[];

export const missionStatusOptions = [
  // "monitoring", // We don't use this status anymore, and it will be removed in the next migration
  "active",
  "inactive",
  "completed",
  "canceled",
  "completed_by_client",
  "completed_by_concurrence",
] as const satisfies readonly MissionStatus[];

export const expenseClaimRequestOptions = [
  "ppe",
  "transportation",
  "mileage",
  "tooling",
  "food",
  "accommodation",
  "travel",
] as const satisfies readonly ExpenseClaimRequestType[];

export const expenseClaimRequestStatus = [
  "to_review",
  "to_validate",
  "pending",
  "approved",
  "refused",
  "canceled",
] as const satisfies readonly ExpenseClaimRequestStatus[];

export const commercialConditionType = [
  "interim",
  "recruitment_office",
  "interim_and_recruitment_office",
] as const satisfies readonly CommercialConditionType[];

export const commercialConditionPricingModel = [
  "grid",
  "job",
  "profile",
] as const satisfies readonly CommercialConditionPricingModel[];

export const commercialConditionStatus = [
  "not_signed",
  "to_validate",
  "signed",
  "sent",
  "refused",
] as const satisfies readonly CommercialConditionStatus[];

export const appRoles = [
  "team_member",
  "talent",
  "accounting_team_member",
  "csm_team_member",
  "consultant_team_member",
  "team_member_marketing",
  "team_member_developer",
  "team_member_independent_administrator",
] as const satisfies readonly AppRole[];

export const timesheetWeekStatus = [
  "completed_by_client",
  "completed_by_talent",
  "rejected_by_talent",
  "rejected_by_client",
  "not_completed",
  "fully_approved",
] as const satisfies readonly TimesheetWeekStatus[];

export const workAccidentRequestStatus = [
  "todo",
  "pending",
  "done",
  "canceled",
] as const satisfies readonly WorkAccidentRequestStatus[];

export const payTrackingStatus = [
  "to_complete",
  "to_process",
  "to_verify",
  "to_send",
  "proceed",
] as const satisfies readonly PayTrackingStatus[];

export const recruitmentOfficeInvoiceRequestStatus = [
  "new",
  "pending",
  "approved",
  "done",
  "canceled",
  "refused",
] as const satisfies readonly RecruitmentOfficeInvoiceRequestStatus[];

export const payTrackingDocumentType = ["pay_slip", "other"] as const satisfies readonly PayTrackingDocumentType[];

export const mealTypes = ["meal_card", "lunch_basket", "none"] as const satisfies readonly MealType[];
