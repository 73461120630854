<template>
  <CalendarPicker
    class="calendar-month-picker"
    v-bind="forwarded"
    :model-value="monthYear"
    month-picker
    @update:model-value="monthYear = $event as unknown as MonthYear"
  >
    <template #trigger>
      <slot name="trigger"></slot>
    </template>
  </CalendarPicker>
</template>

<script setup lang="ts">
import { useForwardPropsEmits } from "radix-vue";
import type { Props as CalendarPickerProps, Emits as CalendarPickerEmits } from "./CalendarPicker.vue";
import type { MonthYear } from "@asap/shared/src/utils/date";
import { getMonth, getYear } from "date-fns";

type Props = CalendarPickerProps & { monthDate?: Date };
type Emits = CalendarPickerEmits & {
  "update:monthDate": [monthDate?: Date];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const forwarded = useForwardPropsEmits(props, emit);

const monthYear = computed<MonthYear | undefined>({
  get: () => {
    if (!props.monthDate) return undefined;
    return {
      month: getMonth(props.monthDate),
      year: getYear(props.monthDate),
    };
  },
  set: (value) => {
    if (!value) emit("update:monthDate", undefined);
    else emit("update:monthDate", new Date(Date.UTC(value.year, value.month, 1)));
  },
});
</script>

<style scoped lang="scss">
.calendar-month-picker {
  width: fit-content;
}
</style>
