export type ErrorSerialized = {
  message: string;
  clientMessage?: string;
};

export interface SharedErrorOptions {
  status?: number;
  context?: unknown;
  clientMessage?: string;
}

/**
 * Options for configuring a SharedError.
 *
 * @property message - Error message. Used for internal logging.
 * @property status - HTML status code sent to client. Defaults to 500.
 * @property context - Context to be sent to Sentry.
 * @property clientMessage - Message to be sent to the client. Defaults to "An error has occurred. Please try again later.".
 */
export class SharedError extends Error {
  public status?: number;
  public clientMessage?: string;
  public context: unknown;

  constructor(
    message: string,
    { status, context, clientMessage }: SharedErrorOptions = {
      status: 500,
      clientMessage: "An error has occurred. Please try again later.",
    }
  ) {
    super(message);
    this.status = status;
    this.context = context;
    this.clientMessage = clientMessage;
  }
}
