import type { TimesheetResetBody, TimesheetResetResponse } from "@asap/shared/src/schemas/timesheet";
import type {
  CreateJobOfferResponse,
  DeleteJobOfferResponse,
  GetJobPostingResponse,
  JobPostingQuery,
  UpdateJobOfferResponse,
} from "@asap/shared/src/types/job-posting";
import type { NavButtonCount } from "@asap/shared/src/types/navbar";
import type {
  QualificationTrainingRequestApproveQuery,
  QualificationTrainingRequestApproveResponse,
} from "@asap/shared/src/types/qualification-training-request";

import type { TalentContractToSignNotificationBody } from "@asap/shared/src/schemas/contract";
import type { SearchByJobNameBody, SearchByMissionNameBody } from "@asap/shared/src/schemas/search";
import type {
  DocumentSenderContactBody,
  DocumentSenderTalentBody,
  HtmlEmailSenderBody,
  MedispaceEmailSenderBody,
  SmsSenderBody,
} from "@asap/shared/src/supabase/use-cases/sender-handler/schema";
import type { LoggerService } from "@asap/shared/src/types/logger-service.ts";
import type {
  TeamMemberAbsenceBody,
  TeamMemberAbsenceResponse,
  TeamMemberLinkSlackResponse,
  TeamMemberOffboardingBody,
  TeamMemberOffboardingStatusQuery,
  TeamMemberOffboardingStatusResponse,
  TeamMemberRevertAbsenceBody,
} from "@asap/shared/src/types/team-member";
import type { ErrorSerialized } from "@asap/shared/src/utils/error";
import { SharedError } from "@asap/shared/src/utils/error.ts";
import type { ListDuplicatesBody, ListDuplicatesResponse } from "../schemas/talent/list-duplicates";
import type { WorkflowUpsertTalentBody, WorkflowUpsertTalentResponse } from "../schemas/workflow";
import type {
  MedicalCheckupRequestApproveBody,
  MedicalCheckupRequestApproveResponse,
} from "../types/medical-checkup-request";
import type { PosthogRequest } from "../types/posthog";

export type ApiEndpoint =
  | {
      path: "timesheet/reset-signatures";
      method: "POST";
      body: TimesheetResetBody;
      response: TimesheetResetResponse;
    }
  | {
      path: "job-posting";
      method: "GET";
      query: JobPostingQuery;
      response: GetJobPostingResponse;
    }
  | {
      path: "job-posting";
      method: "POST";
      query: JobPostingQuery;
      response: CreateJobOfferResponse;
    }
  | {
      path: "job-posting";
      method: "PUT";
      query: JobPostingQuery;
      response: UpdateJobOfferResponse;
    }
  | {
      path: "job-posting";
      method: "DELETE";
      query: JobPostingQuery;
      response: DeleteJobOfferResponse;
    }
  | {
      path: "team-member/absence";
      method: "POST";
      body: TeamMemberAbsenceBody;
      response: TeamMemberAbsenceResponse;
    }
  | {
      path: "team-member/revert-absence";
      method: "POST";
      body: TeamMemberRevertAbsenceBody;
      response: TeamMemberAbsenceResponse;
    }
  | {
      path: "team-member/link-slack";
      method: "PUT";
      response: TeamMemberLinkSlackResponse;
    }
  | {
      path: "team-member/offboarding";
      method: "GET";
      query: TeamMemberOffboardingStatusQuery;
      response: TeamMemberOffboardingStatusResponse;
    }
  | {
      path: "team-member/offboarding";
      method: "PUT";
      body: TeamMemberOffboardingBody;
      response: TeamMemberOffboardingStatusResponse;
    }
  | {
      path: "qualification_training_request/:id/approve";
      method: "PUT";
      body: QualificationTrainingRequestApproveQuery;
      response: QualificationTrainingRequestApproveResponse;
      params: { id: string };
    }
  | {
      path: "navbar-count";
      method: "GET";
      response: NavButtonCount;
    }
  | {
      path: "document/send/contact";
      method: "POST";
      body: DocumentSenderContactBody;
      response: { success: boolean };
    }
  | {
      path: "document/send/talent";
      method: "POST";
      body: DocumentSenderTalentBody;
      response: { success: boolean };
    }
  | {
      path: "email/send_html";
      method: "POST";
      body: HtmlEmailSenderBody;
      response: { success: boolean };
    }
  | {
      path: "contract/notify-talent-contract-to-sign";
      method: "POST";
      body: TalentContractToSignNotificationBody;
      response: { success: boolean };
    }
  | {
      path: "search/job-name";
      method: "POST";
      body: SearchByJobNameBody;
      response: { talentId: string }[];
    }
  | {
      path: "search/mission-name";
      method: "POST";
      body: SearchByMissionNameBody;
      response: { missionId: string }[];
    }
  | {
      path: "sms";
      method: "POST";
      body: SmsSenderBody;
      response: { success: boolean };
    }
  | {
      path: "workflows/upsert-talent";
      method: "POST";
      body: WorkflowUpsertTalentBody;
      response: WorkflowUpsertTalentResponse;
    }
  | {
      path: "talent/list-duplicates";
      method: "POST";
      body: ListDuplicatesBody;
      response: ListDuplicatesResponse;
    }
  | {
      path: "medical-checkup-request/send-medispace-email";
      method: "POST";
      body: MedispaceEmailSenderBody;
      response: { success: boolean };
    }
  | {
      path: "medical-checkup-request/:id/approve";
      method: "PUT";
      body: MedicalCheckupRequestApproveBody;
      response: MedicalCheckupRequestApproveResponse;
      params: { id: string };
    }
  | {
      path: "metrics/posthog";
      method: "POST";
      body: PosthogRequest;
      response: { success: boolean };
    };

export type ApiEndpointMethod<Path extends ApiEndpoint["path"]> = Extract<ApiEndpoint, { path: Path }>["method"];
export type ApiEndpointResponse<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> = Extract<
  ApiEndpoint,
  { path: Path; method: Method }
>["response"];
export type ApiEndpointBody<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> =
  Extract<ApiEndpoint, { path: Path; method: Method }> extends { body: infer Body } ? Body : undefined;
export type ApiEndpointQuery<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> =
  Extract<ApiEndpoint, { path: Path; method: Method }> extends { query: infer Query } ? Query : undefined;

// Ensure params values are strings
type StringifyParams<T> = {
  [K in keyof T]: string;
};

export type ApiEndpointParams<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> =
  Extract<ApiEndpoint, { path: Path; method: Method }> extends { params: infer Params }
    ? StringifyParams<Params>
    : undefined;

export type ApiEndpointOutput<
  Path extends ApiEndpoint["path"],
  Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
  Data = ApiEndpointResponse<Path, Method>,
> =
  | {
      success: true;
      data: Data;
    }
  | { success: false; error: ErrorSerialized };

export type ApiInvokeOptions<
  Path extends ApiEndpoint["path"],
  Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
> = {
  path: Path;
  method: Method;
  body?: ApiEndpointBody<Path, Method>;
  query?: ApiEndpointQuery<Path, Method>;
  params?: ApiEndpointParams<Path, Method>;
  maybeNull?: boolean;
};

export class ApiClient {
  constructor(
    private readonly params: {
      apiUrl: string;
      accessToken: string;
      logger: LoggerService;
    }
  ) {}

  public invoke = async <
    Path extends ApiEndpoint["path"],
    Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
    Data = ApiEndpointResponse<Path, Method>,
  >(
    options: ApiInvokeOptions<Path, Method>
  ): Promise<ApiEndpointOutput<Path, Method, Data>> => {
    try {
      const { apiUrl, accessToken, logger } = this.params;

      if (!apiUrl) throw new SharedError("[ApiClient] Missing API URL");
      if (!accessToken) throw new SharedError("[ApiClient] Missing access token");

      let url = `${apiUrl}/${options.path}`;
      if (options.query) url += `?${new URLSearchParams(options.query)}`;
      if (options.params) {
        Object.entries(options.params).forEach(([key, value]) => {
          url = url.replace(new RegExp(`:${key}`, "g"), value as string);
        });
      }

      const headers: HeadersInit = {
        Authorization: `Bearer ${accessToken}`,
      };
      if ((options.method === "POST" || options.method === "PUT") && options.body)
        headers["Content-Type"] = "application/json";

      const response = await fetch(url, {
        method: options.method,
        headers,
        body: options.body ? JSON.stringify(options.body) : undefined,
      });

      if (!response.ok) {
        try {
          const data = (await response.json()) as ErrorSerialized;
          logger.error(data);
          throw new Error(data.message);
        } catch (exception) {
          logger.error(exception);
          throw new Error("Failed to fetch data");
        }
      }

      const data = (await response.json()) as Data;
      if (!options.maybeNull && !data) throw new Error("No data returned");

      return { success: true, data };
    } catch (exception) {
      const { logger } = this.params;

      logger.error(exception);

      let message = "Unknown error";
      if (exception instanceof Error) message = exception.message;
      else if (typeof exception === "string") message = exception;

      return {
        success: false,
        error: {
          message,
        },
      };
    }
  };
}
