import { get } from "lodash-es";

/** Checks if an item is in a list of items */
export const isin = function <T>(collection: T[], itemToCheck: T, path = "id") {
  return ["string", "number"].includes(typeof itemToCheck)
    ? collection.includes(itemToCheck)
    : collection.some((item) => get(item, path) === get(itemToCheck, path));
};

/** Returns a copy of the array without the itemToRemove */
export const remove = function <T>(items: T[], itemToRemove: T, path = "id"): T[] {
  return ["string", "number"].includes(typeof itemToRemove)
    ? items.filter((item) => item !== itemToRemove)
    : items.filter((item) => get(item, path) !== get(itemToRemove, path));
};

/**
 * - Add item to list if not present in list\n
 * - Remove element from list if present in list
 */
export const toggleSelect = function <T>(items: T[], item: T, path = "id"): T[] {
  if (isin(items, item, path)) return remove(items, item, path);
  else return [...items, item];
};
