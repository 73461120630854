<template>
  <div ref="rowRef" class="row" :class="{ isHeader, isSelected, isActive }">
    <slot :is-hovered="isHovered"></slot>
  </div>
</template>

<script setup lang="ts">
export type Props = {
  isHeader?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  isClickable?: boolean;
};

withDefaults(defineProps<Props>(), {
  isHeader: false,
  skeleton: false,
  isSelected: false,
});

const rowRef = ref<HTMLElement>();
const isHovered = useElementHover(rowRef);
</script>

<style scoped lang="scss">
.row {
  display: grid;
  grid-auto-flow: column;
  height: 56px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--gray-5);
  transition: var(--transition-1);

  &:hover {
    background: var(--gray-8);
  }

  &.isSelected {
    background: var(--gray-7);
  }

  &.isHeader {
    position: sticky;
    top: 0;
    background: var(--white);
    height: 48px;
  }

  &:not(.isHeader) {
    cursor: pointer;
  }
}
</style>
