import { ASAP_WORKSPACE_ID, BOARD_MANAGERS_EMAILS } from "@asap/shared/src/utils/constants";
import type { AppRole } from "@asap/shared/src/utils/supabase.enum";
import { createSharedComposable } from "@vueuse/core";
import { useToast } from "~/composables/useToast";
import { authUserColumns, teamMemberColumns, workspaceColumns } from "~/utils/supabase.columns";
import type { AuthUser, BusinessUnit, TeamMember, Workspace } from "~/utils/supabase.types";

export const useUserMe = createSharedComposable(function () {
  const authUser = ref<AuthUser>();
  const userRoles = ref<AppRole[]>([]);
  const teamMember = ref<TeamMember>();
  const businessUnit = ref<BusinessUnit>();

  const workspaces = ref<Workspace[]>([]);
  const mainWorkspace = ref<Workspace>();
  const currentWorkspace = ref<Workspace>();
  const invitedWorkspaces = ref<Workspace[]>();

  const revieweeWorkspaces = ref<string[]>([]);
  const isBoardManager = ref(false);
  const isIndependent = ref(false);

  const supabaseAuthUser = useSupabaseUser();
  const supabase = useSupabase();

  const { addToastWarning } = useToast();

  watchEffect(async () => {
    if (!supabaseAuthUser.value) return;

    const { data, error } = await supabase
      .from("workspace")
      .select(workspaceColumns)
      .in("id", teamMember.value?.team_member_workspaces.map((membership) => membership.workspace_id) ?? []);

    if (error) throw error;

    workspaces.value = data;
    mainWorkspace.value = data.find((workspace) => workspace.id === teamMember.value?.main_workspace_id);
    currentWorkspace.value = data.find((workspace) => workspace.id === teamMember.value?.workspace_id);
    isIndependent.value = mainWorkspace.value?.id !== ASAP_WORKSPACE_ID;
    invitedWorkspaces.value = data.filter((workspace) => workspace.id !== teamMember.value?.main_workspace_id);
  });

  watchEffect(async () => {
    if (!teamMember.value) return;

    const { data, error } = await supabase
      .from("team_member")
      .select("main_workspace_id")
      .neq("main_workspace_id", ASAP_WORKSPACE_ID)
      .eq("manager_id", teamMember.value.id);

    if (error) throw error;

    revieweeWorkspaces.value = data.map((teamMember) => teamMember.main_workspace_id);
  });

  const refetch = async function () {
    if (!supabaseAuthUser.value) return;

    const { data: authUserData } = await supabase
      .from("auth_user")
      .select(authUserColumns)
      .eq("id", supabaseAuthUser.value.id)
      .single();

    if (authUserData) authUser.value = authUserData;

    const { data: userRoleData } = await supabase
      .from("user_role")
      .select("role")
      .eq("auth_user_id", supabaseAuthUser.value.id);

    if (userRoleData) userRoles.value = userRoleData.map((userRole) => userRole.role);

    const { data: teamMemberData } = await supabase
      .from("team_member")
      .select(teamMemberColumns)
      .eq("auth_user_id", supabaseAuthUser.value.id)
      .maybeSingle();

    if (teamMemberData) {
      teamMember.value = teamMemberData;
      if (teamMemberData.business_unit) {
        businessUnit.value = teamMemberData.business_unit;
      }
    }

    isBoardManager.value = BOARD_MANAGERS_EMAILS.includes(authUser.value?.email ?? "");
  };

  watchEffect(refetch);

  const workspaceId = computed(() => teamMember.value?.workspace_id);

  const isAccounting = computed(() => userRoles.value.includes("accounting_team_member"));
  const isConsultant = computed(() => userRoles.value.includes("consultant_team_member"));
  const isConsultantOnly = computed(
    () => userRoles.value.includes("consultant_team_member") && userRoles.value.length === 2
  );
  const isCSM = computed(() => userRoles.value.includes("csm_team_member"));
  const isExternalCsm = computed(
    () => userRoles.value.includes("external_csm_team_member") && userRoles.value.includes("csm_team_member")
  );
  const isMarketing = computed(() => userRoles.value.includes("team_member_marketing"));
  const isDeveloper = computed(() => userRoles.value.includes("team_member_developer"));
  const isIndependentAdministrator = computed(() => userRoles.value.includes("team_member_independent_administrator"));
  const isSupportRole = computed(() => isAccounting.value || isCSM.value || isMarketing.value || isExternalCsm.value);
  const isSlackIdSet = computed(() => !!teamMember.value?.slack_id);

  const switchWorkspace = async function (workspace: Workspace) {
    if (!teamMember.value) return;

    const { data, error } = await supabase
      .from("team_member")
      .update({ workspace_id: workspace.id })
      .eq("id", teamMember.value.id)
      .select(teamMemberColumns)
      .single();

    if (error) throw error;

    teamMember.value = data;
  };

  const addRoles = async function (roles: AppRole[], authUserId: string) {
    const rolesToAdd = roles.map((role) => {
      return {
        auth_user_id: authUserId,
        role,
      };
    });
    const { error } = await supabase.from("user_role").insert(rolesToAdd);
    if (error) throw new Error(error.message);
  };

  const deleteRoles = async function (roles: AppRole[], authUserId: string) {
    if (roles.includes("team_member_developer")) {
      addToastWarning({
        title:
          "Vous êtes en train de supprimer un rôle essentiel. Pour le rajouter à nouveau, il faudra le faire directement en DB car vous n'aurez plus accès à cette fonctionnalité depuis l'application.",
      });
    }
    const { error } = await supabase.from("user_role").delete().in("role", roles).eq("auth_user_id", authUserId);
    if (error) throw new Error(error.message);
  };

  return {
    authUser,
    teamMember,
    userRoles,
    workspaceId,
    businessUnit,
    isAccounting,
    isBoardManager,
    isIndependentAdministrator,
    isConsultant,
    isConsultantOnly,
    isExternalCsm,
    isCSM,
    isMarketing,
    isDeveloper,
    isSupportRole,
    isSlackIdSet,
    isIndependent,
    workspaces,
    currentWorkspace,
    mainWorkspace,
    revieweeWorkspaces,
    invitedWorkspaces,
    addRoles,
    deleteRoles,
    refetch,
    switchWorkspace,
  };
});
