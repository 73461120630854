import {
  addDays,
  addMonths,
  differenceInBusinessDays,
  differenceInDays,
  differenceInMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  formatDuration,
  getWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { fr } from "date-fns/locale";
import type { Month } from "./supabase.enum";

type DateFormatParams = {
  locales?: string | string[] | undefined;
  options?: Intl.DateTimeFormatOptions;
};

export const dateFormat = function (date: string | number | Date, params?: DateFormatParams) {
  const { locales = "fr" } = params ?? {};
  const { options = { dateStyle: "full" } } = params ?? {};
  return new Intl.DateTimeFormat(locales, options).format(new Date(date));
};

export const dateFormatWithTime = function (date: string | number | Date, params?: DateFormatParams) {
  const { locales = "fr" } = params ?? {};
  const { options = { dateStyle: "short", timeStyle: "short" } } = params ?? {};

  return new Intl.DateTimeFormat(locales, options).format(new Date(date));
};

export const durationTime = function ({
  start,
  end,
}: {
  start: string | number | Date | undefined;
  end: string | number | Date | undefined;
}) {
  if (!start) return undefined;
  if (!end) return undefined;

  const diffInMonths = differenceInMonths(addDays(new Date(end), 1), new Date(start));
  const diffInDays = differenceInDays(addDays(new Date(end), 1), addMonths(new Date(start), diffInMonths));

  return { months: diffInMonths, days: diffInDays };
};

export const durationFormat = function ({
  start,
  end,
}: {
  start: string | number | Date | undefined;
  end: string | number | Date | undefined;
}) {
  const duration = durationTime({ start, end });

  if (!duration) return undefined;

  return formatDuration({ months: duration.months, days: duration.days }, { locale: fr, delimiter: " et " });
};

export const startOfFrenchMonth = function (date: string | number | Date) {
  const currentDate = new Date(date);
  currentDate.setDate(1);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate;
};

export const endOfFrenchMonth = function (date: string | number | Date) {
  const currentDate = startOfFrenchMonth(date);
  currentDate.setMonth(currentDate.getMonth() + 1);
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate.setHours(23, 59, 59, 999);
  return currentDate;
};

export const formatDateForDB = (date: string | Date) => format(new Date(date), "yyyy-MM-dd");

export const formatNullableDateForDB = (date: Date | string | null | undefined) =>
  date ? formatDateForDB(date) : null;

export const formatDurationInHoursMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}h ${minutesLeft.toString().padStart(2, "0")}min`;
};

export const formatMinutesToShortHours = (minutes: number) => {
  if (minutes === 0) return "0h";
  return formatDurationInHoursMinutes(minutes).replace("00", "").replace("min", "").replace(" ", "");
};

export const splitDurationInHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return { hours, minutes: minutesLeft };
};

export const getStartOfWeek = ({ weekNumber, year }: { weekNumber: number; year: number }) => {
  const daysSinceFirst = (weekNumber - 1) * 7 + 1;
  const startDate = new Date(year, 0, daysSinceFirst);

  const dayOfWeek = startDate.getDay();
  const diff = startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

  return new Date(startDate.setDate(diff));
};

export const getMonday = (date: Date) => {
  const formatedDate = new Date(date);
  const day = date.getDay();
  const diff = formatedDate.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(formatedDate.setDate(diff));
};

export const getDatesInWeek = ({ weekNumber, year }: { weekNumber: number; year: number }) => {
  const startOfWeek = getStartOfWeek({ weekNumber, year });
  const dates: Date[] = [];

  for (let i = 0; i < 7; i++) {
    dates.push(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i));
  }
  return dates;
};

export const sortDatesFromRecentToOld = (dates: Date[] | string[]) => {
  return dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
};

export const sortDatesFromOldToRecent = (dates: Date[] | string[]) => {
  return dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
};

/**
 * Calculates the week number and year for a given date.
 * This function aims to match the results of SQL's `EXTRACT(WEEK FROM ...)` and `get_year_from_date(...)` functions.
 */
export const getWeekAndYear = (date: Date): { weekNumber: number; year: number } => {
  const weekNumber = getWeek(date, { weekStartsOn: 1 });
  let year = date.getFullYear();

  // Handle the case where week 1 is at the end of December
  // This matches the behavior of the SQL get_year_from_date function
  if (weekNumber === 1 && date.getMonth() === 11) year += 1;

  return { weekNumber, year };
};
interface DateRange {
  start_at: Date | string | null;
  end_at: Date | string | null;
}

const formatYearMonth = (input: string | Date) => {
  const date = new Date(input);
  return date.getFullYear() * 100 + date.getMonth() + 1;
};

export const filterByHappeningOnDateMonth = <T extends DateRange>(items: T[], today?: Date) => {
  if (!today) today = new Date();
  const formattedToday = formatYearMonth(today);

  return items.filter((item) => {
    if (!item.start_at || !item.end_at) return false;

    const startDate = formatYearMonth(item.start_at);
    const endDate = formatYearMonth(item.end_at);

    return startDate <= formattedToday && formattedToday <= endDate;
  });
};

export const pickDayFromPreviousMonth = (date: Date) => {
  const previousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  return previousMonth;
};

type Period = {
  start: Date;
  end: Date;
};

export type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type Params = {
  periodOne: Period;
  periodTwo: Period;
  /** 0-indexed month */
  monthDate: Date;
};

export type Result = {
  daysInCommon: string[];
};

export function getWorkingDaysInMonth(date: Date) {
  const firstDayOfMonth = startOfMonth(date);
  const lastDayOfMonth = endOfMonth(date);

  return differenceInBusinessDays(lastDayOfMonth, firstDayOfMonth);
}

export type MonthYear = {
  month: number;
  year: number;
};

export function getWeekNumbersInMonth(month: number, year: number): number[] {
  if (month < 1 || month > 12) {
    throw new Error("Invalid month");
  }

  const startDate = startOfMonth(new Date(year, month - 1));
  const endDate = endOfMonth(new Date(year, month - 1));

  const weekNumbers = new Set<number>();

  eachDayOfInterval({ start: startDate, end: endDate }).forEach((date) => {
    weekNumbers.add(getWeek(date, { weekStartsOn: 1 }));
  });

  return Array.from(weekNumbers);
}

export function getWeekDaysFromDateAndMonth(date: Date, month: Month) {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });

  const dates = eachDayOfInterval({ start, end });

  return dates.filter((d) => format(d, "MMMM").toLowerCase() === month.toLowerCase());
}
