<template>
  <Row v-slot="{ isHovered }" :is-header="isHeader" :style="tableStyleObject">
    <CellHeader v-if="isHeader"> Talent </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="ppeRequest">
      {{ getFullName(ppeRequest.contract.talent!) }}
    </CellText>

    <CellHeader v-if="isHeader"> Statut </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="ppeRequest" has-padding-inline>
      <StatusBadge :variant="mapButtonVariantToStatus(ppeRequest.status)">
        {{ translatePpeRequestStatus.fr[ppeRequest.status] }}
      </StatusBadge>
    </Cell>

    <CellHeader v-if="isHeader"> WSM </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="ppeRequest" has-padding-inline>
      <template v-if="ppeRequest?.wsm">
        <Tooltip v-if="ppeRequest.wsm" :content="getFullName(ppeRequest.wsm)">
          <TeamMemberAvatar :team-member="ppeRequest.wsm" />
          <TextEllipsis>
            {{ getFullName(ppeRequest.wsm) }}
          </TextEllipsis>
        </Tooltip>
      </template>
      <template v-else> - </template>
    </Cell>

    <CellHeader v-if="isHeader"> Demandé le </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="ppeRequest">
      <div style="display: flex; align-items: center; gap: 4px">
        <span v-if="ppeRequest.is_created_from_app">📱</span>
        {{ dateFormat(ppeRequest.created_at, { options: { dateStyle: "medium" } }) }}
      </div>
    </CellText>

    <CellHeader v-if="isHeader"> Prix </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="ppeRequest">
      {{ ppeRequest.price ? `${ppeRequest.price}€` : "-" }}
    </CellText>

    <CellHeader v-if="isHeader"> Consultant </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="ppeRequest" has-padding-inline>
      <template
        v-if="ppeRequest.owner && (ppeRequest.is_created_from_app ? ppeRequest.status === 'to_validate' : true)"
      >
        <TeamMemberAvatar :team-member="ppeRequest.owner" />
        <TextEllipsis>
          {{ getFullName(ppeRequest.owner) }}
        </TextEllipsis>
      </template>
      <template v-else> - </template>
    </Cell>

    <template v-if="showAction">
      <CellOptions :is-header="isHeader" :is-active="isHovered" @click.prevent="showCommandModal = true" />
    </template>

    <LazyPpeRequestCommandModal
      v-if="showCommandModal && ppeRequest"
      v-model:ppe-request="ppeRequest"
      @on-close="showCommandModal = false"
    />
  </Row>
</template>

<script setup lang="ts">
import type { PpeRequest } from "~/utils/supabase.types";
import { usePpeRequest } from "~/composables/usePpeRequest";
import { translatePpeRequestStatus } from "@asap/shared/src/utils/translate";
import { dateFormat } from "@asap/shared/src/utils/date";
import { getFullName } from "@asap/shared/src/utils/people";

const props = defineProps<{
  ppeRequest?: PpeRequest;
  isHeader?: boolean;
  isSkeleton?: boolean;
}>();

const emit = defineEmits<{
  "update:ppeRequest": [value: PpeRequest];
  onChange: [];
}>();
const { ppeRequest } = useVModels(props, emit);

const showCommandModal = ref(false);

const { mapButtonVariantToStatus } = usePpeRequest();

const tableStyleObject = { gridTemplateColumns: `repeat(6, minmax(110px, 1fr)) 68px` };

const showAction = computed(() => {
  return ppeRequest.value?.status !== "approved" && ppeRequest.value?.status !== "to_validate";
});
</script>
