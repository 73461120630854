import type { CompanyInsuranceRequestStatus } from "@asap/shared/src/utils/supabase.enum";
import type { StatusBadgeVariant } from "../ui/status-badge/types";

export const mapButtonVariantToStatus = (status: CompanyInsuranceRequestStatus): StatusBadgeVariant => {
  switch (status) {
    case "new":
      return "danger";
    case "sent":
      return "warning";
    case "approved_temporarily":
      return "warning";
    case "approved_but_maximum_reached":
      return "warning";
    case "approved_but_only_contract_minimum":
      return "warning";
    case "approved":
      return "success";
    case "refused":
    case "terminated":
    default:
      return "neutral";
  }
};
