import type { WorkspaceType } from "@asap/shared/src/supabase/shared/types";
import { ASAP_WORKSPACE_ID } from "@asap/shared/src/utils/constants";
import type { AppRole } from "@asap/shared/src/utils/supabase.enum";
import { teamMemberColumns } from "~/utils/supabase.columns";
import type { TeamMember } from "~/utils/supabase.types";

export const useTeamMemberMe = function () {
  const { teamMember } = useUserMe();

  return teamMember;
};

export const useTeamMember = function () {
  const supabase = useSupabase();

  const selectSingle = async function (id: string) {
    const { data, error } = await supabase.from("team_member").select(teamMemberColumns).eq("id", id).single();
    if (error) throw new Error(error.message);
    return { data };
  };

  const selectMultipleByBusinessUnit = async (businessUnitIds: string[]) => {
    const { data, error } = await supabase.from("team_member").select("id").in("business_unit_id", businessUnitIds);
    if (error) {
      throw new Error(error.message);
    }
    return { data };
  };

  const selectMultiple = async (workspaceType?: WorkspaceType) => {
    const query = supabase
      .from("team_member")
      .select(teamMemberColumns)
      .order("first_name", { ascending: true, referencedTable: "auth_user" });

    if (workspaceType === "internal") query.eq("workspace_id", ASAP_WORKSPACE_ID);
    else if (workspaceType === "external") query.neq("workspace_id", ASAP_WORKSPACE_ID);

    const { data, error } = await query;
    if (error) throw new Error(error.message);
    return { data };
  };

  const selectByRole = async (role: AppRole) => {
    const { data, error } = await supabase
      .from("team_member")
      .select(teamMemberColumns)
      .eq("auth_user.user_role.role", role);
    if (error) {
      throw new Error(error.message);
    }
    return { data };
  };

  const update = async function (id: string, teamMember: Partial<TeamMember>) {
    const { data, error } = await supabase
      .from("team_member")
      .update(teamMember)
      .eq("id", id)
      .select(teamMemberColumns)
      .single();

    if (error) throw new Error(error.message);

    return { data };
  };

  return { selectSingle, selectMultiple, selectByRole, selectMultipleByBusinessUnit, update };
};
