export const useLinkSlack = createSharedComposable(function () {
  const { teamMember, refetch } = useUserMe();
  const { addToastError, addToast, addToastSuccess } = useToast();
  const { loading: loadingLinkSlack, invoke } = useApi();

  // We do not want to call this function multiple times at the same time
  // So the first call run the real function, the others just wait for the first to finish
  // We don't reset this to not spam the user with toast (cause most of the time the error will not go away)
  let linkSlackPromise: Promise<void> | null = null;
  const linkSlackOnce = async () => {
    if (linkSlackPromise) return linkSlackPromise;

    linkSlackPromise = linkSlack();

    await linkSlackPromise;
  };
  const linkSlack = async () => {
    if (!teamMember.value) return addToastError({ title: "Impossible de récupérer tes informations" });

    addToast({
      type: "warning",
      title: "Ton compte n'est pas encore relié à Slack",
      description: "On va le faire pour toi, ça ne prendra que quelques secondes...",
      duration: 5000,
    });

    const result = await invoke({ path: "team-member/link-slack", method: "PUT", query: undefined });

    if (!result.success) {
      logger.error("deserializeFromClientToSimplifiedFormat", result.error);

      addToastError(
        {
          description: "Impossible de récupérer ton compte Slack, est-ce que tu t'es déjà connecté dessus ?",
        },
        result.error.clientMessage || result.error.message
      );
    } else {
      addToastSuccess({
        title: "Ton compte a bien été relié à Slack !",
        description: "Tu pourras maintenant recevoir des notifications directement sur Slack",
        duration: 5000,
      });

      // So everyone have the new information
      await refetch();
    }
  };

  return {
    loadingLinkSlack,
    linkSlackOnce,
  };
});
