<template>
  <Avatar :size="size">
    <AvatarImage v-if="filestackUrl" :src="downloadUrl(filestackUrl)" />
    <AvatarFallback v-if="hasName(teamMember)" :color-key="getFullName(teamMember)">
      {{ fallback(getFullName(teamMember)) }}
    </AvatarFallback>
    <AvatarFallback v-else-if="teamMember.email" :color-key="teamMember.email">
      {{ fallback(teamMember.email) }}
    </AvatarFallback>
  </Avatar>
</template>

<script setup lang="ts">
import type { AvatarSize } from "~/components/ui/avatar/types";
import { useFilestackUtils } from "~/composables/useFilestack";
import type { TeamMemberForAvatar } from "~/utils/supabase.types";
import { getFullName, hasName } from "@asap/shared/src/utils/people";

const props = defineProps<{
  teamMember: TeamMemberForAvatar;
  size?: AvatarSize;
}>();

// Fixes the following issue: https://github.com/vuejs/language-tools/issues/3820
const { teamMember } = toRefs(props);

const { downloadUrl } = useFilestackUtils();

const filestackUrl = computed(() => props.teamMember.auth_user?.avatar_file?.filestack_url);

const fallback = (text: string) => text[0];
</script>
