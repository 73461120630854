import type { CustomErrorSerialized } from "@asap/shared/src/supabase/shared/CustomError";
import { FunctionsHttpError } from "@supabase/supabase-js";

export const isAbortError = (error: any) => {
  return typeof error === "object" && !!error && "message" in error && error.message.toLowerCase().includes("aborted");
};

export const getErrorMessage = function (error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const getServerErrorMessage = function (error: any) {
  if (error?.data?.message) return error?.data?.message;
  return getErrorMessage(error);
};

export const deserializeFromClientToSimplifiedFormat = async (
  error: CustomErrorSerialized | unknown
): Promise<{
  status?: number;
  message: string;
}> => {
  if (!error)
    return {
      message: "Une erreur inconnue est survenue",
    };

  if (error instanceof FunctionsHttpError) {
    try {
      const context = await error.context.json();

      let status: number | undefined;
      if ("status" in context && typeof context.status === "number") {
        status = context.status;
      }

      if ("internalMessage" in context && typeof context.internalMessage === "string")
        return {
          status,
          message: context.internalMessage,
        };
      if ("message" in context && typeof context.message === "string")
        return {
          status,
          message: context.message,
        };
    } catch {
      // We continue to try other error types
    }
  }

  return {
    message: getErrorMessage(error),
  };
};
