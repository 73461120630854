import type { BUSINESS_UNITS } from "@asap/shared/src/utils/constants";
import { useTeamMember } from "./useTeamMember";
import { useUserMe } from "./useUser";

export type BusinessUnitName = (typeof BUSINESS_UNITS)[number];

export const useBusinessUnit = () => {
  const isInBusinessUnit = ref(false);
  const { isSupportRole, isDeveloper } = useUserMe();

  const supabase = useSupabase();
  const selectSingleById = async (id: string) => {
    const { data, error } = await supabase.from("business_unit").select(businessUnitColumns).eq("id", id).single();
    if (error) throw new Error(error.message);
    return { data };
  };

  const canSwitchBusinessUnit = computed<boolean>(() => {
    return isSupportRole.value || isDeveloper.value || true;
  });

  /**
   * You can use the checkUserBusinessUnit method to
   * allow for "beta testing" certain features depending on the business unit.
   */
  const checkUserBusinessUnit = async (businessUnitName: BusinessUnitName[]) => {
    const { selectMultipleByBusinessUnit } = useTeamMember();
    const { teamMember } = useUserMe();

    const { data, error } = await supabase.from("business_unit").select("id").in("name", businessUnitName);
    if (error) throw new Error(error.message);

    const businessUnitIds = data.map((businessUnit) => businessUnit.id);

    const betaTestersId = (await selectMultipleByBusinessUnit(businessUnitIds)).data.map((teamMember) => teamMember.id);
    if (!teamMember.value) return;
    isInBusinessUnit.value = betaTestersId.includes(teamMember.value.id);
  };

  return { selectSingleById, isInBusinessUnit, checkUserBusinessUnit, canSwitchBusinessUnit };
};
