import type { NavButtonCount } from "@asap/shared/src/types/navbar";

export function useNavbar() {
  const { invoke } = useApi();
  const getNavbarCount = async (): Promise<NavButtonCount> => {
    const response = await invoke({
      path: "navbar-count",
      method: "GET",
      body: undefined,
      query: undefined,
    });

    if (!response.success) {
      throw new Error("Failed to get navbar count");
    } else {
      return response.data;
    }
  };

  return { getNavbarCount };
}
