<template>
  <div ref="superComboboxRef" class="SuperCombobox" @click="focusOnInput()">
    <div ref="superComboboxSearchRef" class="SuperComboboxSearch">
      <SuperComboboxSearchIcon />

      <div class="SuperComboboxSearchContent">
        <template v-if="isMultiple">
          <div v-for="(option, index) in modelValue" :key="index" style="overflow: hidden">
            <slot
              name="selected-option"
              :item="option"
              :index="index"
              :unselect="unselect"
              :is-selected-option-removable="isSelectedOptionRemovable"
            >
              <!-- The #selected-option slot allows for the customization of the selected options -->

              <!-- -------- -->
              <!-- Example: -->
              <!-- -------- -->

              <!-- <template #selected-option="{ unselect }">
              <SelectTag v-for="(option, index) in modelValue" :key="index">
                {{ option }}
                <SelectTagRemove @click="unselect(option)" />
              </SelectTag>
              </template> -->
            </slot>
          </div>
        </template>

        <SuperComboboxSearchInput
          ref="superComboboxInputRef"
          v-model="searchTerm"
          :placeholder="isMultiple ? (modelValue.length ? undefined : placeholder) : placeholder"
          :style="{ maxWidth: `${searchRect.width.value - 48}px` }"
        />
      </div>
    </div>

    <div ref="superComboboxHeaderRef">
      <slot name="header"></slot>
    </div>

    <div ref="superComboboxViewportRef" class="SuperComboboxViewport">
      <div
        v-for="(option, index) in optionsToDisplay"
        :key="index"
        :data-index="index"
        @click="toggleSelect(option)"
        @pointermove="activeIndex = index"
      >
        <slot
          name="option"
          :item="option"
          :index="index"
          :is-selected="isin(modelValue, option, keyPath)"
          :is-active="index === activeIndex"
        >
          <!-- The #option slot allows for the customization of the listed options. -->

          <!-- -------- -->
          <!-- Example: -->
          <!-- -------- -->

          <!-- <template #option="{ item, isActive }">
            <SuperComboboxItem :is-active="isActive">
              {{ item }}
            </SuperComboboxItem>
          </template> -->
        </slot>
      </div>

      <!-- Empty state slot -->
      <slot v-if="!optionsToDisplay.length" name="empty" :search-term="searchTerm">
        <SuperComboboxEmpty>
          Pas de résultats
          <template v-if="searchTerm"> pour "{{ searchTerm }}" </template>
        </SuperComboboxEmpty>
      </slot>
    </div>

    <div ref="superComboboxFooterRef">
      <slot name="footer" :search-term="searchTerm" :select="select">
        <!-- The #footer slot allows for the customization of the footer area. -->

        <!-- -------- -->
        <!-- Example: -->
        <!-- -------- -->

        <!-- <template #footer>
        <SuperComboboxFooter >
          <Button>
            Create new item
          </Button>
        </SuperComboboxFooter>
        </template> -->
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { get, isNumber, last, max, min } from "lodash-es";
import { isin } from "~/utils/select";

export type Props<T> = {
  /**
   * The list of selected options.
   */
  modelValue: T[];

  /**
   * The list of selectable options.
   */
  options?: T[];

  /**
   * Determines if multiple selections are allowed.
   */
  isMultiple?: boolean;

  /**
   * Determines if options should be hidden when selected.
   * Defaults to `true` when `isMultiple` is true.
   */
  hideOptionWhenSelected?: boolean;

  /**
   * The placeholder text for the combobox input.
   */
  placeholder?: string;

  /**
   * Determines if the selected options can be removed.
   * Defaults to `true` when `isMultiple` is true.
   */
  isSelectedOptionRemovable?: boolean;

  /**
   * The search term for filtering options.
   */
  searchTerm?: string;

  /**
   * The key path to use for the selected options.
   */
  keyPath?: string;
};

export type Emits<T> = {
  /** Event handler called when the modelValue changes. */
  "update:modelValue": [value: T[]];
  /** Event handler called when the searchTerm of the combobox changes. */
  "update:searchTerm": [value: string];
  /** Event handler called when an option is selected. */
  onSelect: [option: T];
  /** Event handler called when an option is unselected. */
  onUnselect: [option: T];
};

const props = withDefaults(defineProps<Props<T>>(), {
  options: () => [],
  hideOptionWhenSelected: (props) => !!props.isMultiple,
  isSelectedOptionRemovable: (props) => !!props.isMultiple,
  placeholder: "Rechercher",
  searchTerm: "",
  keyPath: "id",
});

const emit = defineEmits<Emits<T>>();

const { modelValue, searchTerm } = useVModels(props, emit);

const superComboboxRef = ref<HTMLElement>();
const superComboboxSearchRef = ref<HTMLElement>();
const superComboboxHeaderRef = ref<HTMLElement>();
const superComboboxViewportRef = ref<HTMLElement>();
const superComboboxFooterRef = ref<HTMLElement>();

const comboboxRect = useElementBounding(superComboboxRef);
const searchRect = useElementBounding(superComboboxSearchRef);
const headerRect = useElementBounding(superComboboxHeaderRef);
const footerRect = useElementBounding(superComboboxFooterRef);

const windowRect = useWindowSize();

// Dynamically set the available height for the super combobox viewport based on window and element dimensions
watchEffect(() => {
  if (
    superComboboxRef.value &&
    superComboboxSearchRef.value &&
    superComboboxHeaderRef.value &&
    superComboboxViewportRef.value
  ) {
    // Using comboboxRect.top as a reactive value instead of `superComboboxRef.value.getBoundingClientRect().top`
    // fixes the issue of calculating the available height when SuperCombobox is used in a Popover
    const availableHeight =
      windowRect.height.value -
      comboboxRect.top.value -
      searchRect.height.value -
      headerRect.height.value -
      footerRect.height.value;

    superComboboxViewportRef.value.style.setProperty(
      "--super-combobox-content-available-height",
      `${availableHeight}px`
    );
  }
});

const superComboboxInputRef = ref();
const inputRef = computed(() => superComboboxInputRef.value?.input);
const { focused } = useFocus(inputRef);

const focusOnInput = function () {
  focused.value = true;
};

const { options } = toRefs(props);

const optionsToDisplay = computed(() => {
  return props.hideOptionWhenSelected
    ? options.value.filter((option) => !isin(modelValue.value, option, props.keyPath))
    : options.value;
});

const scrollTo = (index: number) => {
  if (superComboboxViewportRef.value) {
    const optionElement = superComboboxViewportRef.value.querySelector(`[data-index="${index}"]`) as HTMLElement;

    if (optionElement) {
      const viewportTop = superComboboxViewportRef.value.offsetTop;
      const viewportHeight = superComboboxViewportRef.value.offsetHeight;

      const optionElementTop = optionElement.offsetTop;
      const optionElementHeight = optionElement.offsetHeight;

      const scrollTop = optionElementTop - viewportTop - (viewportHeight - optionElementHeight) / 2;

      superComboboxViewportRef.value.scrollTo({ top: scrollTop });
    }
  }
};

const { index: activeIndex, next, prev } = useCycleList(optionsToDisplay);

const navDirection = ref<"up" | "down">("down");

const haveOptionsBeenInitiatedOnce = ref(false);

watchEffect(() => {
  if (haveOptionsBeenInitiatedOnce.value) return;
  if (options.value.length) haveOptionsBeenInitiatedOnce.value = true;
});

whenever(
  haveOptionsBeenInitiatedOnce,
  () => {
    if (!props.isMultiple && modelValue.value.length) {
      // activeIndex.value = optionsToDisplay.value.findIndex((option) => isEqual(option, modelValue.value[0]));
      activeIndex.value = optionsToDisplay.value.findIndex((option) =>
        props.keyPath
          ? get(option, props.keyPath) === get(modelValue.value[0], props.keyPath)
          : option === modelValue.value[0]
      );

      nextTick(() => {
        scrollTo(activeIndex.value);
      });
    }
  },
  { immediate: true }
);

// Handle ArrowDown key: set direction, move to next, and scroll
useKeyStroke(
  "ArrowDown",
  (e) => {
    e.preventDefault();
    navDirection.value = "down";
    next();
    scrollTo(activeIndex.value);
  },
  { target: superComboboxRef }
);

// Handle ArrowUp key: set direction, move to previous, and scroll
useKeyStroke(
  "ArrowUp",
  (e) => {
    e.preventDefault();
    navDirection.value = "up";
    prev();
    scrollTo(activeIndex.value);
  },
  { target: superComboboxRef }
);

// Handle Enter key: select option if valid
useKeyStroke(
  "Enter",
  (e) => {
    e.preventDefault();

    if (isNumber(activeIndex.value)) {
      const option = optionsToDisplay.value[activeIndex.value];
      if (option) toggleSelect(option);
    }
  },
  { target: superComboboxRef }
);

// If the search term is empty, it removes the last element from the modelValue array.
useKeyStroke(
  "Backspace",
  () => {
    if (props.isSelectedOptionRemovable) {
      if (!searchTerm.value) {
        const lastItem = last(modelValue.value);
        if (lastItem === undefined) return;
        if (lastItem === null) return;
        unselect(lastItem);
      }
    }
  },
  { target: superComboboxRef }
);

/**
 * Selects an option and adds it to the modelValue array.
 * It also sets the focus back to the search input and adjust the UI
 * Then, it updates the UI in the next DOM update flush to scroll to the next element.
 * @param {T} option - The option to be selected.
 */
const select = function (option: T) {
  if (props.isMultiple) modelValue.value = [...modelValue.value, option];
  else modelValue.value = [option];

  emit("onSelect", option);

  focusOnInput();

  const index = activeIndex.value;

  nextTick(() => {
    const nextActiveIndex =
      navDirection.value === "down" ? min([index, optionsToDisplay.value.length - 1]) : max([0, index - 1]);

    if (nextActiveIndex !== undefined) activeIndex.value = nextActiveIndex;

    scrollTo(activeIndex.value);
  });

  nextTick(() => {
    if (optionsToDisplay.value.length === 0) searchTerm.value = "";
  });
};

/**
 * Unselects an option and removes it from the modelValue array.
 * It also sets the focus back to the search input
 * Then, it updates the UI in the next DOM update flush to scroll back to the element that was just removed.
 * @param {T} optionToRemove - The option to be unselected.
 */
const unselect = function (optionToRemove: T) {
  modelValue.value = remove(modelValue.value, optionToRemove, props.keyPath);
  focusOnInput();

  nextTick(() => {
    const nextActiveIndex = optionsToDisplay.value.findIndex((option) => option === optionToRemove);
    activeIndex.value = nextActiveIndex !== -1 ? nextActiveIndex : 0;

    scrollTo(activeIndex.value);
  });

  emit("onUnselect", optionToRemove);
};

const toggleSelect = function (option: T) {
  if (isin(modelValue.value, option, props.keyPath)) {
    if (props.isSelectedOptionRemovable) unselect(option);
  } else select(option);
};
</script>

<style lang="scss" scoped>
.SuperCombobox {
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--gray-5);
  box-shadow: var(--shadow-3);
  height: fit-content;
}

.SuperComboboxViewport {
  --padding: 8px;
  overflow: auto;
  padding: var(--padding);
  max-height: calc(var(--super-combobox-content-available-height) - 2 * var(--padding) - 64px);
}

.SuperComboboxSearch {
  --padding-block: 8px;
  display: flex;
  padding-block: var(--padding-block);
  padding-inline: 8px;
  gap: 6px;
  min-height: calc(48px - 2 * var(--padding-block));
  border-bottom: 1px solid var(--gray-6);
  overflow: hidden;
}

.SuperComboboxSearchContent {
  --padding-block: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  min-height: calc(32px - 2 * var(--padding-block));
  overflow: hidden;
}
</style>
