<template>
  <div
    ref="modalOverlayRef"
    class="ModalOverlay"
    tabindex="0"
    @click.self="emit('onClose')"
    @keydown.esc="emit('onClose')"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";
import { sleep } from "@asap/shared/src/utils/sleep";

export type Props = { initialFocus?: boolean };

export type Emits = { onClose: [] };

const props = withDefaults(defineProps<Props>(), {
  initialFocus: true,
});
const emit = defineEmits<Emits>();

const modalOverlayRef = ref();

const initialFocus = ref(props.initialFocus);

const focusTrap = useFocusTrap(modalOverlayRef, {
  immediate: true,
  initialFocus: () => initialFocus.value,
  clickOutsideDeactivates: true,
  onPostActivate: async () => {
    await sleep(1);
    setTimeout(() => {
      initialFocus.value = false;
    }, 1);
  },
});

provide("focusTrap", focusTrap);
</script>

<style lang="scss">
.ModalOverlay {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.15);
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  padding: 8px;

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
