<template>
  <Row :is-header="isHeader" :style="`grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 50px`">
    <CellHeader v-if="isHeader"> Société </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <div v-if="!isReady" class="PreviewFile skeleton-loading"></div>
      <FilePreviewTooltip v-if="!disabledDocumentPreview && fileToDisplay?.filestack_url" :file="fileToDisplay">
        <img :src="thumbnail(fileToDisplay?.filestack_url)" class="PreviewFile" />
      </FilePreviewTooltip>
      <Tag>
        <TagIcon :icon="mapIcon.company" />
        <TagText> {{ commercialCondition.company?.name }} </TagText>
      </Tag>
    </Cell>

    <CellHeader v-if="isHeader"> Type </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <TextEllipsis>
        {{ translateCommercialConditionType.fr[commercialCondition.type] }}
      </TextEllipsis>
    </Cell>

    <CellHeader v-if="isHeader"> Business Unit </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <TextEllipsis>
        {{ commercialCondition.business_unit?.emoji }} {{ commercialCondition.business_unit?.name }}
      </TextEllipsis>
    </Cell>

    <CellHeader v-if="isHeader">Statut</CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition">
      <CommercialConditionStatusBadge :status="commercialCondition.status" />
    </Cell>

    <CellHeader v-if="isHeader">Date</CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="commercialCondition" has-padding-inline>
      <Tooltip
        v-if="commercialCondition.created_by"
        :content="`Créé par ${getFullName(commercialCondition.created_by)}`"
      >
        <AuthUserAvatar v-if="commercialCondition.created_by" :auth-user="commercialCondition.created_by" />
      </Tooltip>

      {{ dateFormat(commercialCondition.created_at, { options: { dateStyle: "medium" } }) }}
    </Cell>

    <CellOptions
      v-show="showAction"
      :is-header="isHeader"
      :is-active="isHovered"
      @click.prevent="showCommandModal = true"
    />

    <LazyCommercialConditionCommandModal
      v-if="showCommandModal && props.commercialCondition"
      :commercial-condition="props.commercialCondition"
      @on-close="showCommandModal = false"
      @on-delete="emit('onDelete', $event)"
    />
  </Row>
</template>

<script setup lang="ts">
import type { CommercialCondition } from "~/utils/supabase.types";
import { useFilestackUtils } from "~/composables/useFilestack";
import { dateFormat } from "@asap/shared/src/utils/date";
import { translateCommercialConditionType } from "@asap/shared/src/utils/translate";
import { getFullName } from "@asap/shared/src/utils/people";
import { ASAP_WORKSPACE_ID } from "@asap/shared/src/utils/constants";

const props = defineProps<{ isHeader?: boolean; commercialCondition?: CommercialCondition; isSkeleton?: boolean }>();

const emit = defineEmits<{
  onDelete: [value: CommercialCondition];
}>();

const { isReady, thumbnail } = useFilestackUtils();

const showCommandModal = ref(false);

const isAsapCommercialCondition = ref(false);

const { workspaceId, teamMember } = useUserMe();

const isIndependent = teamMember.value?.main_workspace_id !== ASAP_WORKSPACE_ID;

const commercialConditionOwner = props.commercialCondition?.auth_user.team_member;

const isHovered = ref(false);

const showAction = computed(() => {
  const unactionableStatus = ["signed"];
  return props.commercialCondition?.status && !unactionableStatus.includes(props.commercialCondition.status);
});

const disabledDocumentPreview =
  // // is independent & not owner of the commercial condition
  (isIndependent && commercialConditionOwner?.workspace_id !== workspaceId.value) ||
  // // is independent & not a validated commercial condition
  (isIndependent && props.commercialCondition && props.commercialCondition.status === "to_validate");

onMounted(() => {
  if (commercialConditionOwner?.workspace_id === ASAP_WORKSPACE_ID) isAsapCommercialCondition.value = true;
});

const fileToDisplay = computed(
  () => props.commercialCondition?.signed_file ?? props.commercialCondition?.generated_file
);
</script>

<style scoped lang="scss">
.ActionButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-inline: 12px;
  height: 32px;
  border-radius: 100px;
  background: var(--gray-6);
  transition: var(--transition-1);
  cursor: pointer;
}

.PreviewFile {
  height: 36px;
  width: 24px;
  flex-shrink: 0;
  border-radius: 3px;
  object-fit: cover;
  object-position: left top;
  border: 1px solid var(--gray-6);
}

.SignedFile {
  height: 256px;
  width: 196px;
  border-radius: 5px;
  object-fit: cover;
  object-position: left top;
}
</style>
